import {
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import { Box, FormControl, Input } from "@mui/joy";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import * as forge from "node-forge";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Loading as Auth0Loading, UnauthorizedDialog } from "components";
import { useApiCore, useConfiguration } from "contexts";
import { logInfo } from "utils";
import clearDecentriqStorage from "utils/clearDecentriqStorage";
import ApiCoreWrapper from "wrappers/ApiCoreWrapper/ApiCoreWrapper";
import {
  EnclaveTokenContext,
  localStorageEnclaveTokenKeyId,
} from "./EnclaveTokenContext";

// Set this to `sessionStorage` to use per-tab caching for the enclave token. Useful for development.
// const enclaveTokenStorage = localStorage;
const enclaveTokenStorage = sessionStorage;

type EnclaveToken = {
  email: string;
  token: string;
};

const Auth0Wrapper = memo<React.PropsWithChildren>(({ children }) => {
  const { configuration } = useConfiguration();
  const [enclaveToken, setEnclaveToken] = useStorageState(
    enclaveTokenStorage,
    localStorageEnclaveTokenKeyId
  );

  useEffect(() => {
    if (enclaveToken) {
      const token: EnclaveToken = JSON.parse(enclaveToken);
      const claimsBase64 = token.token.split(".")[1];
      const claimsBytes = forge.util.binary.base64.decode(claimsBase64);
      const claimsString = new TextDecoder().decode(claimsBytes);
      const claims = JSON.parse(claimsString);
      if (claims.exp && Number.isInteger(claims.exp)) {
        const expiration = new Date(claims.exp * 1000);
        const now = new Date();
        // The grace period before the expiration where we already trigger a new login, even though the token should still be valid.
        // X + 7 + 7 guarantees a minimum of X days where the token is still valid, but a new login will be triggered on page refresh.
        // See driver enclave authentication code for more details.
        const gracePeriodMs = (2 + 7 + 7) * 24 * 60 * 60 * 1000;
        if (now.getTime() + gracePeriodMs > expiration.getTime()) {
          logInfo("Enclave token expiring, triggering re-login");
          clearDecentriqStorage();
          setEnclaveToken(null);
        }
      } else {
        throw new Error("Enclave token has malformed/missing `exp` claim");
      }
    }
  }, [enclaveToken, setEnclaveToken]);

  // If an enclave token is not available we trigger a more complex authentication flow,
  // the goal of which is to gather the right tokens from Auth0 and exchange those for
  // an enclave token.
  return (
    <Auth0Provider
      authorizationParams={{
        audience: configuration.auth0Audience,
        redirect_uri: window.location.origin,
      }}
      clientId={configuration.auth0ClientId}
      domain={configuration.auth0Domain}
      useRefreshTokens={true}
    >
      <Auth0Consumer>
        {enclaveToken ? (
          <RetriggerLoginIfDifferentUser
            enclaveToken={enclaveToken}
            setEnclaveToken={setEnclaveToken}
          >
            <EnclaveTokenContext.Provider value={{ enclaveToken }}>
              {children}
            </EnclaveTokenContext.Provider>
          </RetriggerLoginIfDifferentUser>
        ) : (
          <AuthFlowToGetEnclaveToken setEnclaveToken={setEnclaveToken} />
        )}
      </Auth0Consumer>
    </Auth0Provider>
  );
});
Auth0Wrapper.displayName = "Auth0Wrapper";

const RetriggerLoginIfDifferentUser = ({
  enclaveToken,
  setEnclaveToken,
  children,
}: React.PropsWithChildren<{
  enclaveToken: string;
  setEnclaveToken: (value: string | null) => void;
}>) => {
  const auth0 = useAuth0();
  useEffect(() => {
    if (auth0.user) {
      const token: EnclaveToken = JSON.parse(enclaveToken);
      if (auth0.user.email !== token.email) {
        logInfo(
          "Enclave token found belonging to different user, triggering enclave email MFA"
        );
        clearDecentriqStorage();
        setEnclaveToken(null);
      }
    }
  }, [auth0.user, enclaveToken, setEnclaveToken]);
  if (auth0.user) {
    return children;
  } else {
    return <Auth0Loading />;
  }
};

const AuthFlowToGetEnclaveToken = ({
  setEnclaveToken,
}: {
  setEnclaveToken: (value: string | null) => void;
}) => {
  const [authErrorState, setAuthErrorState] = useState<string | null>(null);

  const auth0 = useAuth0();
  if (!auth0.user || !auth0.user.email) {
    return <Auth0Loading />;
  }

  return (
    <DisplayErrorIfNeeded authErrorState={authErrorState}>
      <ApiCoreWrapper>
        <RetrieveEnclaveToken
          email={auth0.user.email}
          setAuthErrorState={setAuthErrorState}
          setEnclaveToken={setEnclaveToken}
        />
      </ApiCoreWrapper>
    </DisplayErrorIfNeeded>
  );
};

const DisplayErrorIfNeeded = (
  props: React.PropsWithChildren<{
    authErrorState: string | null;
  }>
) => {
  const { children, authErrorState } = props;

  const { logout } = useAuth0();
  const signOut = useCallback(() => {
    clearDecentriqStorage();
    logout({
      logoutParams: {
        federated: true,
        returnTo: window.location.origin,
      },
    });
  }, [logout]);

  if (authErrorState !== null) {
    return (
      <Dialog disableEscapeKeyDown={true} open={true}>
        <Typography align="center" color="textPrimary" variant="h6">
          <strong>{authErrorState}</strong>
        </Typography>
        <DialogContent>
          <Typography align="center" component="div" variant="body2">
            Please sign in again
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={signOut}>
            Sign in
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return children;
  }
};

const Auth0Error = memo<{ message?: string }>(
  ({ message = "Unknown error" }) => {
    const { logout } = useAuth0();
    return (
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <UnauthorizedDialog
          error={message}
          onClose={() => {
            clearDecentriqStorage();
            logout({ logoutParams: { returnTo: window.location.origin } });
          }}
          open={true}
        />
      </Box>
    );
  }
);
Auth0Error.displayName = "Auth0Error";

const Auth0Consumer = memo<React.PropsWithChildren>(({ children }) => {
  const { error, isLoading } = useAuth0();
  return withAuthenticationRequired(() =>
    error ? (
      <Auth0Error message={error?.message} />
    ) : isLoading ? (
      <Auth0Loading />
    ) : (
      children
    )
  )({});
});
Auth0Consumer.displayName = "Auth0Consumer";

type EmailMfaState =
  | null
  | { state: "expecting-code-input"; message: string }
  | { state: "waiting"; message: string }
  | { state: "code-retrieved"; code: string }
  | { state: "error"; error: string };

const RetrieveEnclaveToken = (props: {
  email: string;
  setEnclaveToken: (value: string) => void;
  setAuthErrorState: (value: string | null) => void;
}) => {
  const { email, setEnclaveToken, setAuthErrorState } = props;
  const { configuration } = useConfiguration();
  const { sessionManager } = useApiCore();
  const [emailMfaState, setEmailMfaState] = useState<EmailMfaState>(null);
  useEffect(() => {
    const transitionState = async () => {
      let session = await sessionManager.getV2();
      if (!emailMfaState) {
        const request = {
          emailStart: { email },
        };
        setEmailMfaState({
          message: "Requesting email MFA code...",
          state: "waiting",
        });
        await session.sendAuthenticationRequest(request);
        setEmailMfaState({
          message: `An MFA email has been sent to ${email}, please enter the code from the email:`,
          state: "expecting-code-input",
        });
      } else if (emailMfaState.state === "code-retrieved") {
        const request = {
          emailFinish: { code: emailMfaState.code, email },
        };
        logInfo("Auth request:", request);
        setEmailMfaState({
          message: "Verifying MFA code...",
          state: "waiting",
        });
        const authResponse = await session.sendAuthenticationRequest(request);
        if (!authResponse.emailFinish) {
          setEmailMfaState({
            error: "Malformed authentication response",
            state: "error",
          });
        } else {
          logInfo(`Auth response:`, authResponse);
          if (authResponse.emailFinish.incorrectCode) {
            setEmailMfaState({
              message: `The code is incorrect, please enter it again:`,
              state: "expecting-code-input",
            });
          } else if (authResponse.emailFinish.notFound) {
            setEmailMfaState({
              message: `The MFA session cannot be found. Please try to send the email again. If the issue persists, please contact support@decentriq.com`,
              state: "expecting-code-input",
            });
          } else if (
            authResponse.emailFinish.success &&
            authResponse.emailFinish.success.token
          ) {
            setEmailMfaState(null);
            const enclaveToken: EnclaveToken = {
              email,
              token: authResponse.emailFinish.success.token,
            };
            setEnclaveToken(JSON.stringify(enclaveToken));
          } else {
            setEmailMfaState({
              error: "Malformed authentication response",
              state: "error",
            });
          }
        }
      }
    };
    const transitionStateWithTryCatch = async () => {
      try {
        return await transitionState();
      } catch (error) {
        setAuthErrorState(`${error}`);
      }
    };

    transitionStateWithTryCatch();
  }, [
    setEnclaveToken,
    sessionManager,
    configuration.auth0ClientId,
    configuration.auth0Domain,
    setAuthErrorState,
    emailMfaState,
    email,
  ]);

  const [mfaCodeFieldState, setMfaCodeFieldState] = useState("");

  if (!emailMfaState) {
    return <Auth0Loading />;
  }

  switch (emailMfaState.state) {
    case "expecting-code-input":
      return (
        <Dialog disableEscapeKeyDown={true} open={true}>
          <Typography align="center" color="textPrimary" variant="h6">
            <strong>Email MFA</strong>
          </Typography>
          <DialogContent>
            <Typography align="center" component="div" variant="body2">
              {emailMfaState.message}
            </Typography>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setEmailMfaState({
                  code: mfaCodeFieldState,
                  state: "code-retrieved",
                });
              }}
            >
              <FormControl>
                <Input
                  onChange={(e) => setMfaCodeFieldState(e.target.value)}
                  placeholder="MFA code from email"
                  type={"text"}
                />
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              onClick={() => {
                setEmailMfaState({
                  code: mfaCodeFieldState,
                  state: "code-retrieved",
                });
              }}
            >
              Verify
            </Button>
            <Button
              color="inherit"
              onClick={() => {
                setEmailMfaState(null);
              }}
            >
              Send new MFA code
            </Button>
          </DialogActions>
        </Dialog>
      );

    case "waiting":
      return (
        <Dialog disableEscapeKeyDown={true} open={true}>
          <Typography align="center" color="textPrimary" variant="h6">
            <strong>Email MFA</strong>
          </Typography>
          <DialogContent>
            <Typography align="center" component="div" variant="body2">
              {emailMfaState.message}
            </Typography>
          </DialogContent>
        </Dialog>
      );
    case "code-retrieved":
      return (
        <Dialog disableEscapeKeyDown={true} open={true}>
          <Typography align="center" color="textPrimary" variant="h6">
            <strong>Email MFA</strong>
          </Typography>
          <DialogContent>
            <Typography align="center" component="div" variant="body2">
              Verifying code...
            </Typography>
          </DialogContent>
        </Dialog>
      );
    case "error":
      return (
        <Dialog disableEscapeKeyDown={true} open={true}>
          <Typography align="center" color="textPrimary" variant="h6">
            <strong>ERROR ERROR ERROR</strong>
          </Typography>
          <DialogContent>
            <Typography align="center" component="div" variant="body2">
              {emailMfaState.error}
            </Typography>
          </DialogContent>
        </Dialog>
      );
  }
};

const useStorageState = <T extends string | null>(
  storage: Storage,
  key: string
): [T, (value: T) => void] => {
  const [state, setState] = useState<T>(storage.getItem(key) as T);
  const setStateWrapped = useCallback(
    (value: T) => {
      if (value == null) {
        storage.removeItem(key);
      } else {
        storage.setItem(key, value);
      }
      setState(value);
    },
    [key, storage]
  );
  return [state, setStateWrapped];
};

export default Auth0Wrapper;
